import * as React from 'react';
import WebBasic from 'views/WebBasic';

const IndexPage = () => {
  return <WebBasic />;
};

export default IndexPage;

export const Head = () => <title>cognijiwa.ai</title>;
